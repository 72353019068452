.global-nav {
  position: absolute;
  left: $sidebar-width;
  height: $gnb-height;
  width: calc(100% - #{$sidebar-width});
  background-color: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: $contents-padding-left;
  padding-right: 34px;
  z-index: $gnb-z-in;
  border-bottom:1px solid $border-color;
  & > h1{
    font-size:2rem;
  }
  span {
    font-size: 13px;
    font-weight: 400;
  }
  &__user{
    position:relative;
    padding-right:10px;
    cursor: pointer;
    &::after{
      content:'';
      width:14px;
      height:14px;
      background-image: url('../../images/ic_chevron_down_b.svg');
      background-repeat: no-repeat;
      position:absolute;
      background-size: contain;
      right:-6px;
      top:50%;
      transform: translateY(-45%);
    }
    .userimg {
      width: 30px;
      height: 30px;
      background-color: $black;
      margin-left: 16px;
      border-radius: 50px;
      object-fit: cover;
      background-position: center;
     
    }
  }
  
  &__dropdown {
    min-width: 168px;
    height: auto;
    position: absolute;
    background-color: $white;
    box-shadow: 1px 2px 10px rgba(36, 36, 36, 0.12);
    border-radius: 8px;
    top: calc(#{$gnb-height} - 4px);
    right: $contents-padding-right;
    padding: 20px;

    ul {
      padding: 0;
      margin: 0;
      li {
        list-style: none;
        font-size: 14px;
        color: $text_primary3;
        cursor: pointer;
        &:hover{
            color:$text-primary;
        }
      }
    }
  }
}
