.image-preview-area {
  width: 320px;
  height: 400px;
  outline: 1px solid $text_primary5;
  margin-top: 9px;
  box-sizing: border-box;
  position: relative;

  img {
    width: 320px;
    height: 400px;
    object-fit: contain;
    object-position: 50% 50%;
    opacity: 0.5;
  }
  &.responsive {
    width: fit-content;
    height: 100%;
    max-width: calc(100vw - 400px);
    max-height: calc(100vh - 400px);
    overflow: auto;
    @include scroll-hidden;
    img {
      width: unset;
      height: unset;
      display: block;
    }
  }
  .desc-comp {
    width: 48px;
    height: 31px;
    font-size: 1.3rem;
    border: 1px solid $text-primary;
    background-color: $text-primary;
    color: $white;
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.map-navigation-list {
  margin: 7.5px 0;
  li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    span {
      font-size: 14px;
      display: block;
      min-width: 65px;
      margin-right: 10px;
    }
  }
}
