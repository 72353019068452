
.page-user-form {
    .invitation-card{
        width:fit-content;
        border:1px solid $bg_light;
        padding:20px 30px 20px 20px;
        border-radius:8px;
        display:flex;
        align-items: flex-start;
        img{
            width:60px;
            height:60px;
            border-radius:4px;
            object-fit: cover;
            background-position: center;
        }
        &__info{
            padding-left:16px;
        }
    }
}
