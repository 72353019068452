.tooltip {
  position: relative;
  display: inline-block;
  width: fit-content;

  &__contents {
    position: absolute;
    display: none;
    background: linear-gradient(129.55deg, #323232 -5.51%, #404040 110.67%);
    color: #fff;
    border-radius: 10px;
    font-size: 13px;
    width: max-content;
    max-width: 200px;
    padding: 8px 10px;
    left: 50%;
    transform: translate(-50%, calc(-100% + -30px));
    z-index: 30;
  }
  &__trigger {
    cursor: pointer;
    &:hover ~ .tooltip__contents {
      display: block;
    }
    &-icon {
      width: 18px;
      height: 18px;
      background-image: url("../../images/ic_help.svg");
      background-repeat: no-repeat;
      background-position: center;
      margin-left: 2px;
      margin-top: 1px;
    }
  }
}
.react-tiny-popover-container {
  z-index: $tooltip-z-in;
  width: fit-content;
  transform: unset !important;
}
.popover {
  background: linear-gradient(129.55deg, #323232 -5.51%, #404040 110.67%);
  color: #fff;
  border-radius: 10px;
  font-size: 1.4rem;
  padding: 18px 16px;
  max-width: 280px;
  font-weight: 400;
  z-index: $tooltip-z-in;
  word-break: break-all;
  line-height: 20px;
  letter-spacing: -0.04rem;
  filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.2));
  &.tooltip-scroll {
    margin-top: 60px;
    max-height: 200px;
    overflow-y: auto;
    @include scroll-hidden;
  }
  p {
    word-break: break-all;
  }
  &.size-large {
    max-width: 760px;
    position: absolute;
    word-break: break-all;
    min-width: 620px;
  }
  &.size-fit {
    max-width: 280;
    position: absolute;
    word-break: break-all;
    width: fit-content;
  }
  &.ic-question {
    margin-bottom: 0px;
    max-width: 280px;
  }
}
.ic-question-tooltip {
  width: 20px;
  height: 20px;
  background: no-repeat url("../../images/ic_help.svg") center;
  background-size: contain;
}

// base-gray-tooltip
.popover-gray {
  width: fit-content;
  max-width: 320px;
  background: $bg_dark;
  color: $text-primary;
  border-radius: 8px;
  font-size: 1.3rem;
  padding: 16px;
  font-weight: 400;
  z-index: $tooltip-z-in;
  word-break: break-all;
  line-height: 20px;
  letter-spacing: -0.04rem;
  border: 1px solid $line_dark;
  box-shadow: 0px 5px 20px 0px #0000001a;
  &__rich {
    width: 320px;
  }
  &__title {
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 4px;
  }
  &__contents {
    max-height: 200px;
    overflow-y: auto;
    @include scroll-hidden;
  }
  p {
    word-break: break-all;
  }
  &__btn-wrap {
    display: flex;
    align-items: center;
    gap: 16px;
    padding-top: 16px;
    p {
      color: $system_purple;
      font-size: 1.3rem;
      font-weight: 600;
      cursor: pointer;
    }
  }
  &.ic-question {
    margin-bottom: 0px;
    max-width: 280px;
  }
}
.popover-gray-touch {
  display: flex;
  align-items: center;
  gap: 4px;
  p {
    font-size: 14px;
  }
  .circle__chip {
    width: 16px;
    height: 16px;
    border-radius: 100px;
    background-color: $system_purple;
    &.--active {
      background-color: $system_purple;
    }
    &.--inactive {
      background-color: $text_primary5;
    }
    &.--cannot-use {
      background-color: $white;
      border: 3px solid $text-primary;
    }
  }
  .ic-touch-area {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    .ic-question-mark {
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;
      background: no-repeat url("../../images/ic_help.svg") center;
      background-size: contain;
    }
    .ic-info {
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;
      background: no-repeat url("../../images/ic_info.svg") center;
      background-size: contain;
    }
  }
}
