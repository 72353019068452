.page-member-detail {
    .user-basic-info {
        padding-top: 10px;
        display: flex;
        align-items: flex-start;
        margin-bottom: 30px;
    }
    .detail-basic-info {
        p {
            padding: unset;
        }
        .contents-container__grid {
            margin-bottom: 0px;
        }
    }
    .detail-usage-restriction{

    }
    .user-img-container {
        margin-right: 30px;
        width:80px;
        height:80px;
        background-color: map-get($optional, "Grass900");
        position: relative;
        color:$white;
        display:flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size:30px;
        padding-bottom:3px;
    }
  
    .state-change-info {
    }
}
