.base-table {
  width: 100%;
  border-spacing: 0;
  height: calc(100% - #{$pagination-height});
  border-collapse: collapse;
  margin-top: -1px;
  word-break: break-all;
  white-space: normal;
  flex:1;
  .header {
    min-width: 100%;
  }

  .table_img {
    width: 45px;
    height: 45px;
    border-radius: 4px;
    object-fit: cover;
    object-position: center;
  }
  &__tr:hover .base-table__td {
    background-color: $bg_light;
  }
  &.sticky {
    overflow: auto;
    .header,
    .footer {
      position: sticky;
      z-index: 1;
      width: fit-content;
    }
    .header {
      top: 0;
      //   box-shadow: 0px 3px 3px #ccc;
    }
    .footer {
      bottom: 0;
      //   box-shadow: 0px -3px 3px #ccc;
    }
    .body {
      position: relative;
      z-index: 0;
    }
    [data-sticky-td] {
      position: sticky;
    }
    [data-sticky-last-left-td] {
      box-shadow: 1px 0px 0px #ccc;
    }
    [data-sticky-first-right-td] {
      box-shadow: -1px 0px 0px #ccc;
    }
  }

  &.two-depth-header {
    .base-table__tr {
      margin-top: -1px;
    }
    .base-table__th {
      border-right: 1px solid $border-color;
      background-color: $white;
      position: relative;
      overflow: visible;
      text-align: center;
      &:last-child {
        border-right: none;
      }
      .no-parent {
        position: absolute;
        left: 0;
        bottom: -10px;
        width: 100%;
        // background-color: red;
        background-color: $white;
      }
      .sticky-parent {
        position: absolute;
        left: 0;
        bottom: -10px;
        width: 100%;
        // background-color: blue;
        background-color: $white;
      }
    }
    .base-table__td {
      text-align: center;
    }
  }

  &__th,
  &__td {
    overflow: hidden;
    padding: 10px 10px;

    &:first-child {
      padding-left: $contents-padding-left;
    }
    &:last-child {
    }
  }

  &__th {
    //   width:100%;
    height: 40px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    text-align: center;
    background-color: $white;
    color: $text_primary4;
    border-bottom: 1px solid $border-color;
    border-top: 1px solid $border-color;
  }

  &__td {
    height: 60px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    border-bottom: 1px solid $border-color;
    background-color: #fff;
    overflow: hidden;
    //   width: 100% !important;
    &.base-table-footer {
      background-color: $tag_purple_opacity07;
    }
  }
  .ic_ho {
    width: 14px;
    height: 14px;
    opacity: 0.5;
    background-size: contain;
    background-image: url("../../images/ic_corner_down_right.svg");
    background-repeat: no-repeat;
  }
  .ic_sort {
    width: 10px;
    min-width: 10px;
    height: 10px;
    background-size: contain;
    margin-left: 4px;
    background-image: url("../../images/ic_sort.svg");
    background-repeat: no-repeat;
    &.down {
      background-image: url("../../images/ic_sort_down.svg");
      background-repeat: no-repeat;
    }
    &.up {
      background-image: url("../../images/ic_sort_up.svg");
      background-repeat: no-repeat;
    }
  }

  .footer .tr:last-child .td {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #4caf50;
    color: white;
  }

  
}
// 탭내의 테이블일 경우
.inner-tab-table {
  .base-table {
    &__td {
      height: $inner-tab-table;
    }
    &__td,
    &__th {
      &:first-child {
        padding-left: 16px;
      }
    }
  }
}

.two-depth-header {
  .base-table__tr {
    margin-top: -1px;
  }
  .base-table__th {
    border-right: 1px solid $border-color;
    background-color: $white;
    position: relative;
    overflow: visible;
    text-align: center;
    &:last-child {
      border-right: none;
    }
    .no-parent {
      position: absolute;
      left: 0;
      bottom: -10px;
      width: 100%;
      // background-color: red;
      background-color: $white;
    }
    .sticky-parent {
      position: absolute;
      left: 0;
      bottom: -10px;
      width: 100%;
      // background-color: blue;
      background-color: $white;
    }
  }
  .base-table__td {
    text-align: center;
  }
}
.base-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $pagination-height;

  button {
    width: 30px;
    height: 30px;
    border: none;
    font-size: 1.2rem;
    border-radius: 4px;
    cursor: pointer;
  }
  &__previous {
    background-image: url("../../images/ic_previous.svg");
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: center;
    background-color: transparent;
    &.prev-10 {
      background-image: url("../../images/ic_previous_10.svg");
    }
    &.prev-all {
      background-image: url("../../images/ic_previous_all.svg");
    }
    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
  &__number {
    background-color: transparent;
    white-space: nowrap;

    &:disabled {
      background-color: $bg_light;
      color: $text-primary;
      cursor: default;
    }
  }
  &__next {
    background-image: url("../../images/ic_next.svg");
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: center;
    background-color: transparent;
    &.next-10 {
      background-image: url("../../images/ic_next_10.svg");
    }
    &.next-all {
      background-image: url("../../images/ic_next_all.svg");
    }
    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
}

.inner-table {
  border-collapse: collapse;
  border-spacing: 0;
  th {
    height: 40px;
    background-color: $bg_light;
    font-size: 1.4rem;
    color: $text_primary3;
    font-weight: 400;
  }
  td {
    font-size: 1.4rem;
    height: 40px;
  }
  tbody {
    tr {
      border-bottom: 1px solid $bg_light;
      &.auto-height {
        td {
          height: auto;
          .h-40 {
            height: 40px;
            border-bottom: 1px solid $bg_light;
            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
    }
    tr.border-top {
      border-top: 1px solid $bg_light;
    }
  }
  &__img {
    width: 34px;
    height: 34px;
    object-fit: cover;
    background-position: center;
    border-radius: 4px;
  }
}

.access-group-table {
  th:first-child {
    width: 100px;
  }
  th:nth-child(2) {
    width: 250px;
  }
  th:nth-child(3) {
    width: 250px;
  }
  th:nth-child(4) {
    width: 60px;
  }
}

.base-table-wrap {
  display: flex;
  flex-direction: column;
  > div:first-child {
    flex: 1;
    height: auto;
  }
}
