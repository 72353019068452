.page-price {
  padding: 0;
  .gray-box {
    background-color: $bg_light;
    width: 775px;
    border-radius: 10px;
    margin-top: 20px;
    padding: 20px;
    flex-direction: column;
    display: flex;
    gap: 20px;
    &__line {
      display: flex;
      align-items: center;
      .left {
        font-size: 1.4rem;
        min-width: 145px;
        color: $text_primary3;
      }
      .right {
        font-size: 1.4rem;
      }
    }
  }
}
