.sidebar {
  min-width: 200px;
  width: $sidebar-width;
  height: 100%;
  overflow: auto;
  @include scroll-hidden;
  min-height: 100vh;
  position: absolute;
  left: 0;
  background-color: $central_menu;
  &__logo {
    width: 100%;
    height: 60px;
    background: url("../../images/central_logo.svg") no-repeat;
    background-position: 20px 15px;
    background-size: 90px;
  }
  &__bottom-divider {
    width: $sidebar-width;
    padding: 10px 16px;
    height: 1px;
    cursor: initial;
    .line {
      width: 100%;
      height: 1px;
      background-color: $white;
      opacity: 0.2;
    }
  }
  ul {
    padding: 0;
    li {
      list-style: none;
      height: auto;
      // display: flex;
      // align-items: center;
      cursor: pointer;
      &.preparing {
        opacity: 0.3;
      }
      &:hover {
        // background-color: $text_primary;
        a {
          color: $white;
        }
      }
      a {
        font-size: 1.4rem;
        color: $white;
        font-weight: 400;
        width: 100%;
        height: 100%;
        min-height: 43px;
        padding-left: 16px;
        display: flex;
        align-items: center;
        opacity: 0.5;
        &.active {
          background-color: #23184f;
          color: $white;
          opacity: 1;
        }
        &:hover {
          opacity: 1;
        }
      }
      .icon {
        background-image: url("../../images/ic_help.svg");
        background-repeat: no-repeat;
        background-size: contain;
        width: 16px;
        height: 16px;
        min-width: 16px;
        min-height: 16px;
        margin-right: 10px;
        // opacity:0.5
      }
      &.dashboard {
        .icon {
          background-image: url("../../images/ic_dashboard_w.svg");
        }
      }
      &.building {
        .icon {
          background-image: url("../../images/ic_building_w.svg");
        }
      }
      &.product {
        .icon {
          background-image: url("../../images/ic_product_w.svg");
        }
      }
      &.contract {
        .icon {
          background-image: url("../../images/ic_contract_w.svg");
        }
      }
      &.billing {
        .icon {
          background-image: url("../../images/ic_calendar_w.svg");
        }
      }
      &.provider {
        .icon {
          background-image: url("../../images/ic_provider_w.svg");
        }
      }
      &.reservation {
        .icon {
          background-image: url("../../images/ic_book_w.svg");
        }
      }
      &.member {
        .icon {
          background-image: url("../../images/ic_star_w.svg");
        }
      }
      &.organization {
        .icon {
          background-image: url("../../images/ic_organization_w.svg");
        }
      }
      &.user {
        .icon {
          background-image: url("../../images/ic_user_w.svg");
        }
      }
      &.visitor {
        .icon {
          background-image: url("../../images/ic_visitor_w.svg");
        }
      }
      &.notice {
        .icon {
          background-image: url("../../images/ic_speaker_w.svg");
        }
      }
      &.dollar {
        .icon {
          background-image: url("../../images/ic_dollar_w.svg");
        }
      }
      &.work {
        .icon {
          background-image: url("../../images/ic_workorder_w.svg");
        }
      }
      &.cost {
        .icon {
          background-image: url("../../images/ic_creditcard_w.svg");
        }
      }
      &.monitor {
        .icon {
          background-image: url("../../images/ic_monitor_w.svg");
        }
      }
      &.access {
        .icon {
          background-image: url("../../images/ic_provider_w.svg");
        }
        &:hover .icon,
        .active .icon {
          opacity: 1;
        }
      }
      &.iot {
        .icon {
          background-image: url("../../images/ic_iot_w.svg");
        }
        &:hover .icon,
        .active .icon {
          opacity: 1;
        }
      }
      &.cctv {
        .icon {
          background-image: url("../../images/ic_cctv_w.svg");
        }
        &:hover .icon,
        .active .icon {
          opacity: 1;
        }
      }
      &.cctvEvent {
        .icon {
          background-image: url("../../images/ic_cctv_w.svg");
        }
        &:hover .icon,
        .active .icon {
          opacity: 1;
        }
      }
      &.message {
        .icon {
          background-image: url("../../images/ic_message_w.svg");
        }
        &:hover .icon,
        .active .icon {
          opacity: 1;
        }
      }
      &.voc {
        .icon {
          background-image: url("../../images/ic_voc.svg");
        }

        &:hover .icon,
        .active .icon {
          background-image: url("../../images/ic_voc_w.svg");
        }
      }
      &.price {
        .icon {
          background-image: url("../../images/ic_price_g.svg");
        }

        &:hover .icon,
        .active .icon {
          background-image: url("../../images/ic_price.svg");
        }
      }
      &.question {
        .icon {
          background-image: url("../../images/ic_question_w.svg");
        }
        &:hover .icon,
        .active .icon {
          opacity: 1;
        }
      }
      &.bank {
        .icon {
          background-image: url("../../images/ic_bank.svg");
        }

        &:hover .icon,
        .active .icon {
          background-image: url("../../images/ic_bank.svg");
        }
      }
    }
    .two-depth-menu {
      // display: none;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease;
      &.--active {
        // display: block;
        max-height: 400px;
        transition: max-height 0.3s ease-in;
      }
      a {
        padding-left: 40px;
      }
    }
  }
}
