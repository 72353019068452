.page-login {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 100vh;
  .grid-login {
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    & > div {
      display: flex;
      flex-direction: column;
    }
    &__logo {
      width: 90px;
      height: 30px;
      background: url("../../images/central_logo_b.svg") no-repeat;
      background-size: 90px;
      cursor: pointer;
      margin-bottom: 20px;
    }
    h1 {
      font-size: 2.4rem;
      margin-bottom: 10px;
    }
    p {
      font-size: 1.4rem;
      font-weight: 500;
    }
    button {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  .grid-bg {
    grid-column: 2 / span 2;
    background-image: url("../../images/login_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}
