.dim {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  &.top-level {
    z-index: 99999;
  }
}

.dim.error {
  z-index: 999;
}

.base-modal {
  width: fit-content;
  min-width: 200px;
  max-width: 920px;
  height: auto;
  max-height: 690px;
  margin: 0 auto;
  padding: 30px 60px;
  border-radius: 8px;
  background-color: #fff;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: top-to-bottom 0.3s;
  overflow: hidden;
  position: relative;
  .base-modal-title {
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
  }
  &__contents {
    padding-bottom: 20px;
    font-size: 1.4rem;
    text-align: center;
  }
  &__btn-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  // 넓은 모달
  &.dialog-modal {
    padding: unset;
    min-width: 1000px;
    max-width: 1000px;
    max-height: 690px;
    overflow: visible;
    .modal-title {
      padding-left: $contents-padding-left;
      padding-right: $contents-padding-right;
      padding-bottom: 20px;
      padding-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left-area {
        display: flex;
        align-items: center;
        span {
          font-size: 2rem;
          font-weight: bold;
        }
      }
      .right-area {
        display: flex;
      }
    }
    .base-modal__contents {
      padding: unset;
    }
    .base-modal__btn-wrap {
      justify-content: flex-end;
      padding-left: $contents-padding-left;
      padding-right: $contents-padding-right;
      padding-bottom: 20px;
      padding-top: 20px;
    }
    .base-table {
      max-height: 500px;
      &__td {
        height: 46px;
      }
    }
    .base-pagination {
      height: auto;
      padding-top: 20px;
    }
    .base-modal__btn-wrap {
      padding-top: 10px;
    }
    .contents-scroll {
      padding: 0 $contents-padding-left;
      max-height: 550px;
      overflow: auto;
    }
    &.small-size {
      min-width: 500px;
      max-width: 500px;
      max-height: 500px;
      .body {
        max-height: 230px;
      }
      .contents-scroll {
        max-height: 365px;
      }
    }
  }
}

// 뼈대만 있는 모달
.base-abstract-modal {
  min-width: 350px;
  width: 1000px;
  min-width: 1000px;
  height: auto;
  max-height: calc(#{$height100} - 80px);
  margin: 0 auto;
  border-radius: 10px;
  background-color: #fff;
  animation: top-to-bottom 0.3s;
  position: relative;
  display: flex;
  flex-direction: column;
  .abstract-form-scroll {
    height: auto;
    max-height: calc(calc(var(--vh, 1vh) * 100) - 80px);
    display: flex;
    flex-direction: column;
  }
  &__title {
    width: 100%;
    // height: $modal-dialog-title-height;
    display: flex;
    align-items: center;
    padding: 30px;
    &.border-bottom {
      border-bottom: 1px solid $bg_dark;
    }
    h1 {
      font-size: 2.4rem;
      font-weight: bold;
    }
    &.has-list {
      display: flex;
      align-items: flex-start;
      padding-bottom: 20px;
    }
  }
  &__contents {
    flex: 1;
    height: auto;
    overflow-y: auto;
    &-subtitle {
      width: 100%;
      height: 66px;
      display: flex;
      align-items: center;
      margin-top: 10px;
      justify-content: space-between;
      h2 {
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 2.6rem;
      }
    }
  }
  &__btn-wrap {
    width: 100%;
    height: $modal-dialog-btn-wrap-height;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    button.base-btn {
      min-width: 80px;
    }
    button.color-white {
      background-color: $bg_light;
      border: none;
    }
  }
  &.small {
    width: 350px;
    min-width: 350px;
  }
  &.medium {
    width: 540px;
    min-width: 540px;
  }
  &.large {
    width: 760px;
    min-width: 760px;
  }
  &.xlarge {
    width: 1000px;
    min-width: 1000px;
  }
  &.two-depth {
    width: 760px;
    min-width: 760px;
    max-height: calc(#{$height100} - 140px);
  }
  .base-table__td {
    height: 50px;
  }
  .contents-container {
    padding-left: unset;
    height: auto;
  }
  .contents-title__tab > div {
    height: 50px;
  }
}

@keyframes top-to-bottom {
  from {
    opacity: 0;
    margin-top: -50px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}
@keyframes modal-bg-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.labels-modal {
  .container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 30px;
    &__left {
      width: 60%;
      .key-value-title {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        h3 {
          font-size: 16px;
        }
      }
      .key-value-line {
        h3 {
          min-width: 250px;
          font-size: 16px;
        }
      }
    }
    &__center {
      height: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-left: 40px;
      padding-right: 35px;
      button {
        width: 60px;
        height: 36px;
        background-color: #ffffff;
        border: 1px solid #c0c0c0;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          background-color: $tag_gray_opacity07;
        }
      }
      .to-left {
        background: url("../../images/ic_double_chevron_left.svg") no-repeat center;
      }
      .to-right {
        margin-top: 10px;
        background: url("../../images/ic_double_chevron_right.svg") no-repeat center;
      }
    }
    &__right {
      width: 100%;
      h3 {
        font-size: 16px;
      }
    }
  }
}

.execute-control-modal {
  .in-line {
    display: flex;
    align-items: center;

    h4 {
      font-size: 14px;
      font-weight: 400;
      color: $text-primary3;
      width: 90px;
      min-width: 90px;
    }
    p {
      width: 100%;
      text-align: right;
      font-size: 14px;
    }
    input {
      text-align: right;
    }
  }
}

.log-modal {
  table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: none;
    th p {
      font-size: 13px;
      color: $text-primary4;
      text-align: left;
      font-weight: 500;
      padding: 4px 0;
    }
    tr {
      &:nth-child(1) {
        border-bottom: 1px solid $line_dark;
      }
      td {
        font-size: 13px;
        padding: 8px 0;
        border-bottom: 1px solid $line_dark;
      }
    }
  }
}

.change-status-modal {
  text-align: center;
  margin-bottom: 80px;
  // &.no-select {
  //   margin-bottom: 10px;
  // }
  .id {
    width: 80px;
  }
  .status {
    width: 200px;
  }
}
.change-email-modal {
  text-align: center;
  .id {
    width: 80px;
  }
  .status {
    width: 200px;
  }
}
