$central_primary: #393075; //센트럴 프라이머리 컬러
$central_menu: #31275b; //센트럴 메뉴 컬러
$central_menu_text: #c6c7ce;

// 공통 디자인 시스템 추가
$key1: #c99684;
$key2: #809585;
$key3: #9b8885;
$key4: #bfb790;

$system_red: #f96960;
$system_yellow: #ffd335;
$system_green: #26a687;
$system_blue: #217bce;
$system_purple: #4927c9;

$tag_green_opacity07: rgba(38, 166, 135, 0.07);
$tag_red_opacity07: rgba(249, 105, 96, 0.07);
$tag_blue_opacity07: rgba(33, 123, 206, 0.07);
$tag_gray_opacity07: rgba(118, 118, 118, 0.07);
$tag_purple_opacity07: rgba(83, 50, 208, 0.07);

$text_primary: #191919;
$text_primary2: #525252;
$text_primary3: #767676;
$text_primary4: #999999;
$text_primary5: #c0c0c0;
$line_dark: #dbdbdb;
$line_light: #e0e0e0;

$bg_dark: #f2f4f6;
$bg_light: #f8f8fa;
$white: #ffffff;

$optional: (
  "Track900": #c68b79,
  "Grass900": #6f8776,
  "Wooden900": #b5ad88,
  "Clay900": #746865,
  "Ivory900": #f5f3ed,
  "Ivory100": #fbfaf8,
);

$base-markdown-color: $text_primary;
$black: $text_primary;
$white: $white;
$border-color: $bg_light;
$erase-btn: rgba(36, 36, 36, 0.2);
$president: rgba(36, 36, 36, 0.3);

.bg-gray {
  background-color: $bg_light;
}
.bg-blue-opacity07 {
  background-color: $tag_blue_opacity07;
}
.text-blue {
  color: $system_blue;
}
.text-green {
  color: $system_green;
}
.text-purple {
  color: $central_primary;
}
.text-red {
  color: $system_red !important;
}
.text-primary1 {
  color: $text_primary;
}
.text-primary2 {
  color: $text_primary2;
}
.text-primary3 {
  color: $text_primary3;
}
.text-primary4 {
  color: $text_primary4;
}
.text-primary5 {
  color: $text_primary4;
}
