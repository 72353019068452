.page-dashboard {
  padding: 0 30px;
  h2 {
    padding: 20px 0;
  }
  section {
    padding-bottom: 30px;
  }
  .dashboard-grid {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, 110px);
    grid-auto-rows: 100px;
    &__card {
      width: 100%;
      height: 100%;
      background-color: #fff;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
      border-radius: 8px;
      padding: 20px;
      font-size: 1.4rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &.text-white > p,
      &.text-white > span {
        color: #fff !important;
      }
      p {
        position: relative;
        color: $text-primary2;
      }
      span {
        font-size: 20px;
        font-weight: bold;
        display: none;
      }
    }
  }
  .bg-dashboard-card {
    background-color: $tag_purple_opacity07;
    border: 1px solid $tag_purple_opacity07;
    p {
      color: $system_purple;
    }
  }
}
