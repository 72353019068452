.base-btn {
  width: fit-content;
  white-space: nowrap;
  font-size: 1.4rem;
  font-weight: 500;
  background-color: $central_primary;
  border: 1px solid $central_primary;
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 16px;
  color: $white;
  line-height: 16px;
  cursor: pointer;
  &.size-small {
    padding: 0 12px;
    font-size: 1.2rem;
    height: 32px;
  }
  &.size-fixed {
    padding: 0 20px;
    min-width: 100px;
    display: flex;
    justify-content: center;
  }
  &.size-large {
    padding: 0 20px;
    font-size: 1.6rem;
    height: 50px;
    min-width: 100px;
    display: flex;
    justify-content: center;
  }
  &.color-white {
    background-color: $white;
    border: 1px solid $text_primary5;
    color: $text-primary;
    &:hover {
      background-color: $tag_purple_opacity07 ;
    }
    &.no-outline {
      background-color:$bg_light;
      border:none;
    }
    &:disabled {
      color: #fff;
    }
  }
  &:disabled {
    background-color: $text_primary5;
    border-color: $text_primary5;
    &:hover {
      background-color: $text_primary5;
      cursor: default;
    }
  }
}

.base-search-btn {
  font-size: 1.4rem;
  font-weight: 500;
  border: 1px solid $bg_light;
  border-radius: 4px;
  display: flex;
  align-items: center;
  min-width: 40px;
  width: 40px;
  height: 40px;
  padding: 0 16px;
  cursor: pointer;
  background: url("../../images/ic_search.svg") no-repeat $bg_dark;
  background-position: center;
  &:hover {
    background: url("../../images/ic_search.svg") no-repeat $bg_dark;
    background-position: center;
  }
}
.base-clear-btn {
  // 인풋에 들어간 x 버튼
  border: none;
  width: 14px;
  height: 14px;
  background: url("../../images/ic_input_clear.svg") no-repeat;
  background-position: center;
  cursor: pointer;
}
.base-add-btn {
  // 원형에 플러스
  // plus-btn
  width: 20px;
  min-width: 20px;
  height: 20px;
  background-color: $text-primary;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  position: relative;
  background-image: url("../../images/ic_plus_w.svg");
  background-position: center;
  background-size: 16px;
  background-repeat: no-repeat;
}
.base-erase-btn {
  // 원형에 마이너스
  // minus-btn 
  width: 20px;
  min-width: 20px;
  height: 20px;
  background-color: $white;
  border: 1px solid $text_primary3;
  border-radius: 50px;
  cursor: pointer;
  position: relative;
  background-image: url("../../images/ic_minus.svg");
  background-position: center;
  background-size: 16px;
  background-repeat: no-repeat;
}

.base-file-btn {
  // 파일업로드 버튼
  width: 24px;
  min-width: 24px;
  height: 24px;
  background-color: $white;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  position: relative;
  background-image: url("../../images/ic_file_upload.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  &:hover {
    background-color: $bg_light;
  }
}

.base-download-btn {
  // 파일업로드 버튼
  width: 24px;
  min-width: 24px;
  height: 24px;
  background-color: $white;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  position: relative;
  background-image: url("../../images/ic_file_download.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  &:hover {
    background-color: $bg_light;
  }
  &.reservation{
    min-height:40px;
    width:fit-content;
    white-space: nowrap;
    border:1px solid $bg_light;
    background-image:unset;
    position:relative;
    padding:0 8px;
    padding-left:28px;
    &::before{
      content:'';
      position:absolute;
      width:20px;
      height:20px;
      background-image: url("../../images/ic_file_download.svg");
      background-size: 20px;
      left:6px;
      top:50%;
      transform:translateY(-50%);
    }
  }
}

.base-remove {
  width: 24px;
  min-width: 24px;
  height: 24px;
  background-color: $white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  background-image: url("../../images/ic_close.svg");
  background-position: center;
  background-size: 20px;
  background-repeat: no-repeat;
  &:hover {
    background-color: $bg_light;
  }
  &.bg-gray {
    background-color: $bg_light;
  }
}

.base-refresh-btn {
  width: 36px;
  min-width: 36px;
  height: 36px;
  background-color: $white;
  border: 1px solid $text_primary5;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  background-image: url("../../images/ic_refresh.svg");
  background-position: center;
  background-size: 20px;
  background-repeat: no-repeat;
  &:hover {
    background-color: $bg_light;
  }
  &.bg-gray {
    background-color: $bg_light;
  }
}
.base-edit-btn {
  width: 24px;
  min-width: 24px;
  height: 24px;
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  background-image: url("../../images/ic_edit.svg");
  background-position: center;
  background-size: 16px;
  background-repeat: no-repeat;
  border: none;
  &:disabled {
    opacity: 0.3;
    &:hover {
      background-color: unset;
      cursor: default;
    }
  }
  &:hover {
    background-color: $bg_light;
  }
}
.base-up-btn {
  width: 20px;
  min-width: 20px;
  height: 24px;
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  background-image: url("../../images/ic_up.svg");
  background-position: center;
  background-size: 6px;
  background-repeat: no-repeat;
  border: none;
  &:disabled {
    opacity: 0.3;
    &:hover {
      background-color: unset;
      cursor: default;
    }
  }
  &:hover {
    background-color: $bg_light;
  }
}
.base-down-btn {
  width: 20px;
  min-width: 20px;
  height: 24px;
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  background-image: url("../../images/ic_down.svg");
  background-position: center;
  background-size: 6px;
  background-repeat: no-repeat;
  border: none;
  &:disabled {
    opacity: 0.3;
    &:hover {
      background-color: unset;
      cursor: default;
    }
  }
  &:hover {
    background-color: $bg_light;
  }
}
.base-trash-btn {
  width: 24px;
  min-width: 24px;
  height: 24px;
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  background-image: url("../../images/ic_trash_r.svg");
  background-position: center;
  background-size: 16px;
  background-repeat: no-repeat;
  border: none;
  &:disabled {
    opacity: 0.3;
    &:hover {
      background-color: unset;
      cursor: default;
    }
  }
  &:hover {
    background-color: $bg_light;
  }
}

.login-btn {
  width: 240px;
  height: 40px;
  white-space: nowrap;
  font-size: 1.6rem;
  font-weight: 700;
  background-color: $text-primary;
  border: 1px solid $text-primary;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  line-height: 16px;
  cursor: pointer;
}

// 네모 회색 오른쪽 위
.absolute-remove-btn {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 16px;
  right: 16px;
  background-color: $text_primary5;
  background-image: url("../../images/ic_close_w.svg");
  background-position: center;
  background-size: 20px;
  background-repeat: no-repeat;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

// 칩 따로 이동
.base-chip {
  font-size: 12px;
  white-space: nowrap;
  background-color: $bg_light;
  color: $text_primary3;
  padding: 4px 8px;
  border-radius: 50px;
}

.type {
  border-radius: 20px;
  // text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1px;
  width: fit-content;
  padding: 0 8px;
  height: 18px;
  color: $white;
  font-size: 1.2rem;
  white-space: nowrap;
  padding-bottom: 1px;
  &.time {
    background-color: $key4;
  }
  &.open {
    background-color: $key2;
  }
  &.full {
    background-color: $key1;
  }
  &.non-resident {
    background-color: $text_primary5;
  }
}

//temp
.color-chip {
  padding: 2px 10px;
  font-size: 1.1rem;
  border-radius: 50px;
  width: fit-content;
  font-size: 1.2rem;
  position: relative;
  span {
    font-size: 1.2rem !important;
  }
  &.visible {
    background-color: $tag_green_opacity07;;
    color: $system_green;
    padding-bottom: 3px;
  }

  &.green {
    background-color: $tag_green_opacity07;;
    color: $system_green;
    padding-bottom: 3px;
    &.dot {
      padding-left: 18px;
      &::before {
        content: "";
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
        width: 6px;
        height: 6px;
        border-radius: 50%;
        position: absolute;
        background-color: $system_green;
      }
    }
  }
  &.invisible {
    background-color: $tag_red_opacity07;
    color: $system_red;
    padding-bottom: 3px;
  }
  &.red {
    background-color: $tag_red_opacity07;
    color: $system_red;
    padding-bottom: 3px;
  }
  &.draft {
    background-color: $bg_light;
    color: $text_primary5;
    padding-bottom: 3px;
  }
  &.gray {
    background-color: $bg_light;
    color: $text_primary5;
    padding-bottom: 3px;
  }
  &.sell {
    background-color: $tag_blue_opacity07;
    color: $system_blue;
    padding-left: 20px;
    padding-bottom: 3px;

    &::before {
      content: "";
      position: absolute;
      border-radius: 50%;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 6px;
      height: 6px;
      background-color: $system_blue;
    }
  }
  &.use {
    background-color: $tag_green_opacity07;;
    color: $system_green;
    padding-left: 20px;
    padding-bottom: 3px;

    &::before {
      content: "";
      position: absolute;
      border-radius: 50%;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 6px;
      height: 6px;
      background-color: $system_green;
    }
  }
}
