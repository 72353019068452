.react-select-container {
  width: 100%;
  .react-select__control {
    border-color: $line_light;
    min-height: 40px;
    &:hover {
      border-color: $text_primary5;
    }
  }
}
.base-select {
  .react-select {
    &__single-value {
      font-size: 1.3rem;
      color:$text-primary;
      
    }
    &__indicator-separator {
      display: none;
    }
    &__placeholder {
      font-size: 1.3rem;
      color: $text_primary4;
      white-space: nowrap;
      border-color: $text-primary;
      box-shadow: none;
    }
    &__control--is-focused {
      border-color: $text-primary;
      box-shadow: unset;
      &:hover {
        border-color: $text-primary;
      }
    }
    &__menu {
      z-index: $select-z-in;
      padding: 8px;
      margin-top: 10px;
      box-shadow: unset;
      filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.1));
      border: none;
      border-radius: 10px;
    }
    &__menu-list {
      .react-select__option {
        font-size: 1.5rem;
        display: flex;
        align-items: center;
        min-height: 46px;
      }
    }
    &__option {
      background-color: $white;
      padding-right: 16px;
    }
    &__option--is-focused {
      background-color: $bg_light;
      border-radius: 4px;
    }
    &__option--is-selected {
      color: $text_primary;
      font-weight: 600;
      font-size: 1.4rem;
      background: url("../../images/ic_check.svg") $white no-repeat center;
      background-position-x: calc(100% - 8px);
      &:focus {
        background-color: unset;
      }
      &:hover {
        background-color: $bg_light;
      }
    }
    &__option:not(.react-select__option--is-selected) {
      &:hover,
      &:active {
        background-color: $bg_light;
      }
    }
    &__indicators{
      margin-right:10px;
    }
    &__dropdown-indicator {
      color: $text_primary3;
      width:16px;
      height:16px;
      right:10px;
      svg {

       display: none;
      }
      background-image: url('../../images/ic_chevron_down_b.svg');
      background-size:1.6rem;
      background-position:center;
    }
  }
}
