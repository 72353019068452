$max: 180;
$max-font: 100;
$minmax-width: 800; //temp
$max-height: 900; //scroll에 사용
$max-gap: 20;
$height: 500;
$width: 500;
$position: 500;

@mixin position-classes {
  @for $i from 0 through $position {
    $position: $i * 1px !important;
    .top#{$i} {
      top: $position;
    }
    .right#{$i} {
      right: $position;
    }
    .bottom#{$i} {
      bottom: $position;
    }
    .left#{$i} {
      left: $position;
    }
  }
}
@include position-classes;

@mixin margin-classes {
  @for $i from 0 through $max {
    $margin: $i * 1px !important;
    .ma#{$i} {
      margin: $margin;
    }
    .ml#{$i} {
      margin-left: $margin;
    }
    .mr#{$i} {
      margin-right: $margin;
    }
    .mt#{$i} {
      margin-top: $margin;
    }
    .mb#{$i} {
      margin-bottom: $margin;
    }
    .mx#{$i} {
      margin-left: $margin;
      margin-right: $margin;
    }
    .my#{$i} {
      margin-top: $margin;
      margin-bottom: $margin;
    }
  }
}
@include margin-classes;

@mixin padding-classes {
  @for $i from 0 through $max {
    $padding: $i * 1px !important;
    .pa#{$i} {
      padding: $padding;
    }
    .pl#{$i} {
      padding-left: $padding;
    }
    .pr#{$i} {
      padding-right: $padding;
    }
    .pt#{$i} {
      padding-top: $padding;
    }
    .pb#{$i} {
      padding-bottom: $padding;
    }
    .px#{$i} {
      padding-left: $padding;
      padding-right: $padding;
    }
    .py#{$i} {
      padding-top: $padding;
      padding-bottom: $padding;
    }
  }
}
@include padding-classes;

@mixin minmax-classes {
  @for $i from 10 through $minmax-width {
    $value: $i * 1px;
    .minmax#{$i} {
      min-width: $value;
      max-width: $value;
      width: $value;
    }
  }
}
@include minmax-classes;

@mixin max-height-classes {
  @for $i from 36 through $max-height {
    $value: $i * 1px !important;
    .max-height#{$i} {
      max-height: $value;
    }
  }
}
@include max-height-classes;

@mixin font-classes {
  @for $i from 10 through $max-font {
    $value: $i * 0.1rem !important;
    .font#{$i} {
      font-size: $value;
    }
  }
}
@include font-classes;

@mixin font($type: "noto") {
  @if ($type== "noto") {
    font-family: "Noto Sans KR", sans-serif;
  }
  @if ($type== "roboto") {
    font-family: "Roboto, sans-serif";
  }
  @if ($type== "pretendard") {
    font-family: "Pretendard, sans-serif";
  }
}

@mixin scroll-hidden {
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
}

@mixin ellipsis($line: 1) {
  @if ($line == 1) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  } @else {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $line;
  }
}

@mixin height-classes {
  @for $i from 0 through $height {
    $value: $i * 1px !important;
    .h#{$i} {
      height: $value;
    }
  }
}
@include height-classes;

@mixin width-classes {
  @for $i from 0 through $width {
    $value: $i * 1px !important;
    .w#{$i} {
      width: $value;
    }
  }
}
@include width-classes;

@mixin gap-classes {
  @for $i from 0 through $max-gap {
    $gap: $i * 1px !important;
    .gap#{$i} {
      gap: $gap;
    }
  }
}

@include gap-classes;
