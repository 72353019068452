$sidebar-width: 200px;
$gnb-height: 50px;
$contents-title-height: 60px;
$contents-padding-left: 30px;
$contents-padding-right: 30px;
$search-wrap-height: 60px;
$pagination-height: 60px;
$btn-wrap: 80px;
$inner-tab-table: 46px;
// z-index
// (react-table th = 1)
$gnb-z-in: 50;
$select-z-in: 3;
$date-picker-z-in: 99;
$tooltip-z-in: 99;
$toast-z-in: 5;
// vh
$height100: calc(var(--vh, 1vh) * 100);
$contents: $gnb-height + $contents-title-height + $btn-wrap;
$gnb-and-title: $gnb-height + $contents-title-height;

$pt-grid: 7.5px;

// dialog modal
$modal-dialog-btn-wrap-height: 100px;
$modal-dialog-title-height: 90px;

// vh
$full-height: calc(var(--vh, 1vh) * 100);
