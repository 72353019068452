input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-transition: background-color 9999s ease-out;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  -webkit-text-fill-color: inherit !important;
}

// 인풋
.base-input {
  width: 100%;
  position: relative;
  input {
    min-height: 40px;
    max-height: 40px;
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid $line_light;
    padding: 0 10px;
    color: $text_primary;
    font-size: 1.4rem;
    &::placeholder {
      color: $text_primary4;
      font-weight: 400;
      font-size: 1.4rem;
    }
    &:focus,
    &:focus:hover {
      outline: none;
      border: 1px solid $text_primary;
    }
    &:hover {
      border-color: $text_primary;
    }
    &:read-only {
      background-color: $bg_dark;
    }
  }
  &.base-search {
    input {
      padding-right: 40px;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      &:hover,
      &:focus {
        & ~ .base-search-btn {
          border-top-color: $text_primary;
          border-right-color: $text_primary;
          border-bottom-color: $text_primary;
        }
      }
    }
    .base-clear-btn {
      position: absolute;
      top: 13px;
      right: 50px;
      & ~ input {
        padding-right: 65px;
      }
    }
  }
  &.base-right-icon {
    input {
      padding-right: 34px;
    }
  }
  .base-search-btn {
    position: absolute;
    top: 0;
    right: 0;
    border: 1px solid $line_light;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .base-icon-btn {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    &.ic-eyes-open {
      height: 100%;
      width: 40px;
      background-image: url("../../images/ic_pw_open.svg");
      background-size: 24px;
      background-position: center;
      background-repeat: no-repeat;
    }
    &.ic-eyes-close {
      height: 100%;
      width: 40px;
      background-image: url("../../images/ic_pw_close.svg");
      background-size: 24px;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  // .base-clear-btn {
  //   position: absolute;
  //   top: 11px;
  //   right: 44px;
  // }
  .base-clear-btn {
    position: absolute;
    top: 11px;
    right: 10px;
  }
}
.validation-text {
  font-size: 1.2rem !important;
  color: $system_red;
  white-space: nowrap;
  padding-top: 2px;
}
// 데이터피커

.base-datepicker {
  &.full-datepicker {
    .react-datepicker__time-container {
      position: absolute;
      right: -120px;
      box-shadow: 0px 2px 6px rgba(36, 36, 36, 0.12);
    }
  }
  &.react-datepicker {
    border: 0;
    box-shadow: 0px 2px 6px rgba(36, 36, 36, 0.12);
    border-radius: 4px;
  }
  .react-datepicker {
    &__header {
      background-color: $text-primary;
      color: $white;
    }
    &-time__header {
      color: $white;
      font-size: 1.6rem;
      font-weight: 400;
    }
    &__time {
      &-container {
        width: 120px;
      }
      &-box {
        width: 120px !important;
      }
      &-list-item {
        font-size: 1.4rem;
        &--selected {
          background-color: $text-primary2 !important;
        }
      }
    }

    &__current-month {
      font-size: 1.6rem;
      font-weight: 400;
      padding-top: 10px;
      color: $white;
    }
    &__triangle {
      display: none;
    }
    &__day {
      font-size: 1.4rem;
      font-family: "Noto Sans KR", sans-serif;
      border-radius: 100%;
      width: 28px;
      height: 28px;
      padding: 4px;
      &:hover {
        border-radius: 100%;
        opacity: 0.8;
      }
      &-names {
        margin-top: 10px;
      }
      &-name {
        font-size: 1.4rem;
        width: 28px;
        height: 28px;
        padding: 4px;
        color: $white;
      }
      &--today {
        // border: 1px solid $text-primary;
        font-weight: 400;
      }
      &--selected {
        background-color: $text-primary;
        border: 1px solid $text-primary;
        &:hover {
          border-radius: 100%;
          background-color: $text_primary2;
        }
      }
      &--keyboard-selected {
        background-color: $text-primary;
        &:hover {
          background-color: $text_primary2;
        }
      }
    }
  }
  .react-datepicker__navigation--next--with-time {
    right: 2px !important;
  }
}
.react-datepicker-popper {
  z-index: $date-picker-z-in;
}

.end-date-picker {
  .react-datepicker__day {
    &--keyboard-selected:not(.react-datepicker__day--selected) {
      background-color: transparent !important;
      color: inherit !important;
      &:hover {
        background-color: #f0f0f0 !important;
      }
    }
  }
}

// 텍스트에리아
.base-textarea {
  width: 100%;
  position: relative;
  textarea {
    width: 100%;
    min-height: 100px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid $line-light;
    padding: 10px;
    color: $text-primary;
    font-size: 1.3rem;
    resize: none;
    //  &::-webkit-scrollbar {
    //     display: none;
    //   }
    @include font;
    &::placeholder {
      color: $text_primary5;
      font-weight: 400;
    }
    &:focus,
    &:focus:hover {
      outline: none;
      border: 1px solid $text-primary;
    }
    &:hover {
      border-color: $text_primary5;
    }
  }
  &.admin-memo {
    textarea {
      min-height: 80px;
      height: 88px;
    }
  }

  .now-max-length {
    position: absolute;
    right: 8px;
    bottom: 8px;
    background-color: $white;
    color: $text_primary5;
    font-size: 11px;
    span {
      font-size: 1.1rem;
    }
  }
}

input[type="checkbox"],
input[type="radio"] {
  display: none;
}
// 체크박스
input[type="checkbox"] {
  & + label .base-checkbox {
    height: 20px;
    width: 20px;
    border-radius: 50px;
    display: inline-block;
    padding: 0;
    border: 1px solid $text_primary5;

    &:hover {
      border: 1px solid $central_primary;
    }
  }

  &:checked + label .base-checkbox {
    background: url("../../images/ic_check_w.svg") $central_primary;
    background-size: 13px;
    background-position: center;
    background-repeat: no-repeat;
    border: none;
  }
  &:disabled + label .base-checkbox {
    background-color: $bg_light;
    border: 1px solid $line_light;
  }
}

// 라디오버튼
input[type="radio"] {
  & + label .base-radio {
    height: 20px;
    width: 20px;
    border-radius: 50px;
    display: inline-block;
    padding: 0;
    border: 1px solid $text_primary5;

    position: relative;
    &:hover {
      border: 1px solid $central_primary;
    }
  }
  &:checked + label .base-radio {
    border: 1px solid $central_primary;
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $central_primary;
    }
  }
  &:disabled + label .base-radio {
    background-color: $bg_light;
    border: 1px solid $line-light;
  }
}

// 라디오버튼(대표)
.president input[type="radio"] {
  & + label .base-radio {
    height: 22px;
    width: 35px;
    border-radius: 50px;
    display: inline-block;
    padding: 0;
    background-color: $president;
    color: white;
    position: relative;
    &:hover {
      border: 1px solid $bg_light;
    }
    &::after {
      content: "대표";
      position: absolute;
      top: 50%;
      left: unset;
      width: unset;
      height: unset;
      background-color: transparent;
      transform: translateY(-55%) !important;
      padding-left: 6px;
      font-size: 1.1rem;
      // font-weight: 500;
      color: $bg_light;
    }
  }
  &:checked + label .base-radio {
    width: 45px;
    background: url("../../images/ic_check_g.svg") $tag_green_opacity07;
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: 4px;
    border: none;
    &::after {
      font-weight: 700;
      left: 12px;
      color: $system_green;
    }
  }
  &:disabled + label .base-radio {
    background-color: $bg_light;
    border: none;
  }
}

// 체크박스 칩형태
.chip-case input[type="checkbox"] {
  & + label {
    height: 22px;
    width: fit-content;
    border-radius: 50px;
    border: 1px solid $text_primary5;
    display: inline-block;
    background-color: $white;
    color: $text_primary5;
    position: relative;
    &:hover {
      border: 1px solid $text_primary3;
      color: $text_primary3;
    }
    span {
      margin-left: 6px !important;
      margin-right: 6px !important;
    }
  }
  & + label .base-checkbox {
    display: none;
  }
  &:checked + label {
    background-image: unset;
    background-color: $central_primary;
    color: $white;
    border: 1px solid $central_primary;
  }
  &:disabled + label {
    background-color: $bg_light;
    border: 1px solid $bg_light;
    color: $text_primary3;
  }
  &:checked:disabled + label {
    background-color: $tag_green_opacity07;
    color: $system_green;
    border: 1px solid $tag_green_opacity07;
  }
}

// 라디오 칩형태
.chip-case input[type="radio"] {
  & + label {
    height: 22px;
    width: fit-content;
    border-radius: 50px;
    border: 1px solid $text_primary5;
    display: inline-block;
    background-color: $white;
    color: $text_primary5;
    position: relative;
    &:hover {
      border: 1px solid $text_primary3;
      color: $text_primary3;
    }
    span {
      margin-left: 6px !important;
      margin-right: 6px !important;
    }
  }
  & + label .base-radio {
    display: none;
  }
  &:checked + label {
    background-image: unset;
    background-color: $central_primary;
    color: $white;
    border: 1px solid $central_primary;
  }
  &:disabled + label {
    background-color: $bg_light;
    border: 1px solid $bg_light;
    color: $text_primary3;
  }
  &:checked:disabled + label {
    background-color: $tag_green_opacity07;
    color: $system_green;
    border: 1px solid $tag_green_opacity07;
  }
}
.base-multi-select-wrap {
  position: relative;
  .base-multi-select {
    width: 100%;
    height: 40px;

    border: 1px solid $line-light;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 30px;

    &:hover {
      border: 1px solid $text-primary;
    }
    &:focus {
      border-color: $text-primary;
    }

    &__disabled {
      background-color: $bg_light;
      .base-multi-select__chevron {
        display: none;
      }
    }
    &__value {
      font-size: 1.4rem;
      color: $text-primary;
      cursor: default;
      @include ellipsis;
    }
    &__placeholder {
      font-size: 1.4rem;
      color: $text-primary4;
    }
    &__chevron {
      position: absolute;
      width: 16px;
      height: 16px;
      top: 11px;
      right: 10px;
      background-image: url("../../images/ic_chevron_down_b.svg");
      background-repeat: no-repeat;
      background-position: center;
    }
    &__menu {
      width: 100%;
      min-height: 100px;
      position: absolute;
      top: 40px;
      z-index: $select-z-in;
      padding: 8px;
      margin-top: 10px;
      box-shadow: unset;
      background-color: $white;
      filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.1));
      border: none;
      border-radius: 10px;
      ul {
        padding: 0;
      }
      &__menu-list {
        list-style: none;
        font-size: 1.4rem;
        display: flex;
        align-items: center;
        min-height: 36px;
        border-radius: 4px;
        padding: 7.5px 8px;
        cursor: pointer;
        &:hover {
          background-color: $bg_light;
        }
        &.list-all {
          text-decoration: underline;
          &:hover {
            background-color: $white;
            font-weight: 600;
          }
        }
        label {
          cursor: pointer;
        }
      }
    }
  }
}

.base-search-input {
  // BaseInputWithSearch
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-sizing: content-box;

  .react-select__control {
    transition: unset;
  }

  &:hover {
    .base-input {
      input {
        border-color: $text-primary;
      }

      .base-search-btn {
        border-top-color: $text_primary;
        border-right-color: $text_primary;
        border-bottom-color: $text_primary;
      }
    }

    .base-select {
      .react-select__control {
        border-color: $text-primary;
      }
    }
  }

  &__focus {
    .base-input {
      input {
        border-color: $text-primary;
      }

      .base-search-btn {
        border-top-color: $text_primary;
        border-right-color: $text_primary;
        border-bottom-color: $text_primary;
      }
    }

    .base-select {
      .react-select__control {
        border-color: $text-primary;

        &--is-focused {
          border-color: $text-primary;
          box-shadow: unset;
        }
      }
    }
  }

  .base-input {
    input {
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-left: -1px;

      &:focus,
      &:focus:hover {
        outline: none;
        border-color: $text-primary;
        border-left: none;

        .base-search-btn {
          border-top-color: $text_primary;
          border-right-color: $text_primary;
          border-bottom-color: $text_primary;
        }
      }
    }
  }

  .base-select {
    .react-select__control {
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .react-select__value-container {
      padding-right: 0;
    }
    .react-select__control--is-disabled {
      background-color: $white;
      .react-select__indicator {
        opacity: 0.3;
      }
    }
  }
}

.range-datepicker-input {
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: center;
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  min-width: 200px;

  cursor: pointer;

  white-space: nowrap;
  box-sizing: border-box;

  .react-select__control {
    transition: unset;
  }

  input {
    padding: 10px;
    padding-right: 0;
    border: none;
    flex: 1;
    height: 100%;
    outline: none;
    border-radius: 3px;
    cursor: pointer;
    &:disabled {
      background-color: $bg_light;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      & ~ .icon {
        background-color: $bg_light;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
  }

  &:hover {
    border: 1px solid $text-primary;
  }

  .icon {
    width: 36px;
    height: 100%;
    box-sizing: border-box;
    background-image: url("../../images/ic_calendar_b.svg");
    background-repeat: no-repeat;
    background-position: center;
  }
  &.with-search {
    position: relative;
    &:after {
      content: "";
      width: 36px;
      height: 38px;
      border-radius: 4px;
      position: absolute;
      top: 0;
      right: 0;
      background-image: url("../../images/ic_calendar_b.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-color: $white;
    }
    &.base-search-input__focus {
      border-color: $text-primary;
    }

    .react-datepicker-wrapper input {
      min-width: 196px;
      padding-right: 36px;
    }

    .base-select {
      .react-select__control {
        border-color: transparent;
        border: none;
        background-color: transparent;

        &--is-focused {
          border: none;
          border-color: transparent;
          box-shadow: unset;
        }
      }
    }
  }
}
