.base-toggle {
  position: relative;
  display: inline-block;
  width: fit-content;
  input {
   display:none;
    &:checked + .base-toggle__slider {
      background-color: $central_primary;
    }
    &:focus + .base-toggle__slider {
      box-shadow: 0 0 1px $central_primary;
    }
    &:checked + .base-toggle__slider::before {
      transform: translateX(16px);
    }
    &:checked + .base-toggle__label{
      color:$central_primary;
    }
  }
  &__slider {
    position: relative;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width:40px;
    height:24px;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 30px;
    &::before {
      position: absolute;
      content: "";
      height: 18px;
      width: 18px;
      border-radius: 20px;
      left: 3px;
      bottom: 3px;
      background-color: white;
      transition: 0.4s;
    }
  }
  &__label{
    margin-left:48px;
    font-size:1.4rem;
    cursor: pointer;
    white-space: nowrap;
    color:$text_primary5;
    display:flex;
    height:24px;
    padding-bottom:1px;
    align-items: center;
  }
}
