.page-partner-list {
  .search-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .search-section-right {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .add-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.page-partner-detail {
  .contents-container__grid-contents {
    display: flex;
  }
}

.page-partner-parking {
  .contents-container__grid {
    grid-template-columns: 180px 1fr;
  }
}

.permission-table {
  .base-table.sticky {
    overflow: scroll;
    height: calc(100vh - 330px);
  }
  .base-table__td {
    height: 100px;
  }
  .rwd-index {
    width: 16px;
  }
}
.permission-header {
  max-width: 1660px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
