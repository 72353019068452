.base-toast {
  // 우측 하단에 뜨는 success, fail 상태가 있는 토스트
  width: 500px;
  height: auto;
  min-height: 74px;
  max-height: 600px;
  border-radius: 8px;
  background-color: $bg_light;
  animation: modal-show 0.3s;
  overflow: hidden;
  position: fixed;
  padding: 16px 20px;
  bottom: 30px;
  right: $contents-padding-right;
  border: none;
  z-index: $toast-z-in;

  &__contents {
    p {
      padding-top: 10px;
      font-size: 1.4rem;
      color: $text_primary3;
    }
    span {
      font-weight: 700;
      font-size: 1.8rem;
    }
  }
  &__close {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 14px;
    right: 16px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: 20px;
    height: 20px;
    background: url("../../images/ic_close_toast.svg") no-repeat;
    background-position: center;
  }
}

.base-system-toast {
  // 중앙에 뜨는 검정색 시스템 토스트
  width: fit-content;
  height: auto;
  min-height: 50px;
  border-radius: 4px;
  background-color: rgba(25, 25, 25, 0.8);
  animation: modal-show 0.3s;
  overflow: hidden;
  position: fixed;
  padding: 15px 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  z-index: $toast-z-in;

  &__contents {
    p {
      font-size: 1.4rem;
      color: $white;
      white-space: pre;
    }
  }
}

.base-mobile-toast {
  // 모바일에서 사용하는 하단 검정색 시스템 토스트
  max-width: calc(500px - 48px);
  width: calc(100% - 48px);
  height: auto;
  min-height: 36px;
  border-radius: 4px;
  background-color: $text-primary;
  animation: modal-show 0.3s;
  overflow: hidden;
  position: fixed;
  display: flex;
  align-items: center;
  padding: 0 10px;
  bottom: 88px;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  z-index: $toast-z-in;

  &__contents {
    p {
      font-size: 1.4rem;
      color: $white;
      white-space: pre;
    }
  }
}

@keyframes modal-show {
  from {
    opacity: 0;
    margin-right: -50px;
  }
  to {
    opacity: 1;
    margin-right: 0;
  }
}
// @keyframes modal-bg-show {
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// }
