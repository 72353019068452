.product-theme{
    height:auto;
    &__selected{
        width:134px;
        height:80px;
        border:1px solid $text_primary5;
        border-radius:4px;
        margin-right:10px;
    }
    &__colors{
        border:1px solid $text_primary5;
        width:fit-content;
        padding:2px;
        display:grid;
        gap: 2px;
        grid-template-columns: repeat(5, 36px);
        .colors{
            border:1px solid $text_primary5;
            width:100%;
            height:36px;
            cursor: pointer;
        }
    }
    .redg01{
        background: linear-gradient(180deg, #E14A64 0%, #E1774A 100%);
    }
    .redg02{
        background: linear-gradient(180deg, #CD6226 0%, #CD9026 100%);
    }
    .redg03{
        background: linear-gradient(180deg, #EB4848 0%, #EB5904 100%);
    }
    .redg04{
        background: linear-gradient(180deg, #8237A6 0%, #A6377A 100%);
    }
    .greyg01{
        background: linear-gradient(180deg, #212121 0%, #363636 100%);
    }
    .greeng01{
        background: linear-gradient(180deg, #176661 0%, #176658 100%);
    }
    .greeng02{
        background: linear-gradient(180deg, #248654 0%, #4C9B54 100%);
    }
    .blueg01{
        background: linear-gradient(180deg, #207EB3 0%, #2099B3 100%);
    }
    .blueg02{
        background: linear-gradient(180deg, #344DCB 0%, #497FB1 100%);
    }
    .blueg03{
        background: linear-gradient(180deg, #192C4D 0%, #24517A 100%);
    }
}
