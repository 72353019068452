.loading-dim {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  &.top-level {
    z-index: 99999;
  }
}
.base-loading-bar {
  margin: 0 auto;
  &__loading-img {
    width: 100px;
    height: 60px;
    background: url("../../images/central_logo.svg") no-repeat center;
    animation: motion 0.5s linear 0s infinite alternate;
    margin-top: 0;
  }

  @keyframes motion {
    0% {
      margin-top: 0px;
    }
    100% {
      margin-top: 10px;
    }
  }
}
