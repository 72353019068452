.popbill-account {
  .inner-table {
    thead {
      tr {
        th {
          background-color: white;
        }
      }
    }
    tbody {
      border-bottom: 1px solid #f6f6f9;
      tr {
        // border-bottom: 1px solid #f6f6f9;
        border-top: 1px solid #f6f6f9;
        // border-top: none;
        border-bottom: none;
      }
    }

    td {
      padding: 10px 0;
      height: 50px;
    }
  }
}
