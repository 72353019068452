.contents-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: $contents-padding-left;
  padding-right: $contents-padding-right;
  height: $contents-title-height;
  border-bottom: 1px solid $border-color;
  h2 {
    margin-right: 20px;
  }
  &__tab {
    display: flex;
    margin-left:-10px;
    div {
      cursor: pointer;
      margin: 0 10px;
      height: $contents-title-height;
      display: flex;
      align-items: center;
      position: relative;
      span {
        font-size: 1.4rem;
        color: $text_primary5;
      }
      &.active {
        span {
          color: $text-primary;
          font-weight:500;
        }
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          left: 0;
          bottom: 0;
          height: 2px;
          background-color: $text-primary;
        }
      }
      &:hover{
        span{
          color: $text-primary;
        }
      }
    }
  }
  &__bread-crumbs {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    color: $text_primary5;
    a span:hover {
      color: $text-primary;
    }
    span {
      margin-left: 14px;
      position: relative;
      &::after {
        content: ">";
        position: absolute;
        right: -10px;
        top: 50%;
        transform: translateY(-50%);
      }
      &:last-child::after {
        display: none;
      }
      &:hover{
        color:$text-primary;
        cursor:pointer;
      }
    }
  }
}
