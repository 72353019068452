* {
  box-sizing: border-box;
}
html {
  font-size: 10px;
  font-family: "Noto Sans KR", sans-serif;
  // font-family: 'Pretendard, sans-serif';
  height: 100%;
  color: $black;
  word-break: break-all;
}
body {
  background-color: $white;
  margin: 0;
  padding: 0;
  height: $height100;
}

// ::-webkit-scrollbar{
// }
// ::-webkit-scrollbar-thumb{
//   background-color: transparent;
// }
// ::-webkit-scrollbar-track{
//   background-color: #fff;
// }

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: unset;
}

.h_2 {
  font-size: 2em;
}

a {
  text-decoration: none;
  color: unset;
  color: $system_purple;
  text-decoration: unset;
  &:hover {
    color: $system_purple;
  }
  &.text-hilight {
    color: $system_purple;
    text-decoration: unset;
    &:hover {
      color: $system_purple;
      text-decoration: underline;
    }
    &.target-blank {
      display: inline;
      vertical-align: middle;
    }
  }
}

.text-underline {
  text-decoration: underline !important;
}

.ic-target-blank {
  display: inline-block;
  min-width: 18px;
  max-width: 18px;
  width: 18px;
  height: 18px;
  background: url("../../images/ic_target_blank_g.svg") no-repeat center;
  vertical-align: middle;
}
.font-weight-bold {
  font-weight: 700;
}
.font-weight-medium {
  font-weight: 500;
}
.w-100 {
  width: 100%;
}
.w-fit {
  width: fit-content;
}
.d-none {
  display: none !important;
}
.d-block {
  display: block !important;
}
.d-flex {
  display: flex !important;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-start {
  display: flex;
  align-items: flex-start;
}
.flex-center {
  display: flex;
  align-items: center;
}
.flex-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-center-end {
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
}
.flex-center-start {
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
}
.flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
}
.justify-contents-center {
  justify-content: center !important;
}
.justify-contents-start {
  justify-content: flex-start !important;
}
.justify-contents-end {
  justify-content: flex-end !important;
}
.justify-contents-between {
  justify-content: space-between;
}
.align-items-center {
  align-items: center !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-hilight {
  text-decoration: underline;
}
.text-line {
  text-decoration: line-through;
}
.pre-discount {
  text-decoration: line-through;
  color: $text_primary4;
}
.no-wrap {
  white-space: nowrap;
}
.pre-formatted {
  white-space: pre-wrap;
}

.cursor-pointer {
  cursor: pointer;
}
.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.overflow-scroll {
  overflow-y: auto;
}

.overflow-x-scroll {
  overflow-x: auto;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

// 1줄 ... 처리
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.h-36 {
  min-height: 36px;
}

.lh-18 {
  line-height: 1.8;
}

// 2줄 ... 처리
.ellipsis2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  //필요에 따라 line-height를 써야할 수 있다.
}
.text-color-transparent {
  color: transparent;
}

.required {
  position: relative;
  &::before {
    content: "*";
    color: $system_red;
    font-size: 1.4rem;
    left: -8px;
    top: -2px;
    position: absolute;
  }
}

.fit-contents {
  width: fit-content;
}

.not-available {
  color: $line_light !important;
  .contents-container__grid-index {
    color: $line_dark !important;
  }
}

.border-top {
  border-top: 1px solid $line_light;
}
.border-bottom {
  border-bottom: 1px solid $line_light;
}
.border-gray {
  border: 1px solid $line_light;
}

.canvas {
  width: 30px;
  height: 30px;
  overflow: hidden;
  display: block;
}

.base-markdown {
  font-size: 1.5rem;
  white-space: initial;

  .toastui-editor-contents {
    ol > li::before {
      font-size: 1.5rem;
      margin-top: 2px;
      color: $base-markdown-color;
    }
    ul > li::before {
      margin-top: 11px;
      background-color: $base-markdown-color;
    }
    h1 {
      color: $base-markdown-color;
      span {
        font-size: 2.4rem !important;
        line-height: 2.8rem !important;
      }
    }
    h2 {
      color: $base-markdown-color;
      span {
        font-size: 2.2rem !important;
        line-height: 2.3rem !important;
      }
    }
    h3 {
      color: $base-markdown-color;
      span {
        font-size: 2rem !important;
      }
    }
    h4 {
      color: $base-markdown-color;
      span {
        font-size: 1.8rem !important;
      }
    }
    h5 {
      color: $base-markdown-color;
      span {
        font-size: 1.6rem !important;
      }
    }
    h6 {
      color: $base-markdown-color;
      span {
        font-size: 1.4rem !important;
      }
    }
    p {
      font-size: 1.5rem !important;
      color: $base-markdown-color;
      span {
        color: $base-markdown-color;
      }
    }
    table {
      color: $base-markdown-color;
    }
  }
}

.flex-files {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  &__wrap {
    display: flex;
    align-items: center;
    .delete-btn-x {
      width: 20px;
      height: 20px;
      background-image: url("../../images/ic_close.svg");
      background-size: contain;
      cursor: pointer;
    }
  }
}

.chip {
  padding: 2px 10px;
  font-size: 1.2rem;
  border-radius: 50px;
  position: relative;
  &.visible {
    background-color: $tag_green_opacity07;
    color: $system_green;
    padding-bottom: 3px;
  }
  &.green {
    background-color: $tag_green_opacity07;
    color: $system_green;
    padding-bottom: 3px;
    &.dot {
      padding-left: 18px;
      &::before {
        content: "";
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
        width: 6px;
        height: 6px;
        border-radius: 50%;
        position: absolute;
        background-color: $system_green;
      }
    }
  }
  &.invisible {
    background-color: $tag_red_opacity07;
    color: $system_red;
    padding-bottom: 3px;
  }
  &.red {
    background-color: $tag_red_opacity07;
    color: $system_red;
    padding-bottom: 3px;
  }
  &.draft {
    background-color: $bg_light;
    color: $text_primary4;
    padding-bottom: 3px;
  }
  &.gray {
    background-color: $bg_light;
    color: $text_primary4;
    padding-bottom: 3px;
  }
  &.sell {
    background-color: $tag_blue_opacity07;
    color: $system_blue;
    padding-left: 20px;
    padding-bottom: 3px;

    &::before {
      content: "";
      position: absolute;
      border-radius: 50%;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 6px;
      height: 6px;
      background-color: $system_blue;
    }
  }
  &.use {
    background-color: $tag_green_opacity07;
    color: $system_green;
    padding-left: 20px;
    padding-bottom: 3px;

    &::before {
      content: "";
      position: absolute;
      border-radius: 50%;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 6px;
      height: 6px;
      background-color: $system_green;
    }
  }
  &.blue {
    background-color: $tag_blue_opacity07;
    color: $system_blue;
  }
}

.delete-btn-x {
  width: 20px;
  height: 20px;
  background-image: url("../../images/ic_close.svg");
  background-size: contain;
  cursor: pointer;
}

.ic-link {
  width: 20px;
  height: 20px;
  background-image: url("../../images/ic_link.svg");
  background-size: contain;
  cursor: pointer;

  &:hover {
    background-image: url("../../images/ic_link_b.svg");
  }
}

.ic-file {
  width: 20px;
  height: 20px;
  background-image: url("../../images/ic_file.svg");
  background-size: contain;
  cursor: pointer;

  &:hover {
    background-image: url("../../images/ic_file_b.svg");
  }
}

.list-none {
  list-style: none;
}

.bg-white {
  background-color: $white !important;
}
